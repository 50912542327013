import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function MetricsHeader({
  // Props
  sort,
  setSort,
}) {
  //---------------------------------------------------------------------------
  // Update the `sort` object for use by sortManager
  //---------------------------------------------------------------------------
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid container id="headersRow" sx={{ alignItems: 'flex-end' }}>
        <Grid size={3}>
          <ColumnHeader
            id="group"
            display="Group"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={6}>
          <ColumnHeader
            id="name"
            display="Metrics"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={3}>
          <ColumnHeader
            id="user"
            display="People"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MetricsHeader;
