import React from 'react';

import AssignmentIcon from '@mui/icons-material/Assignment';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ApprovalForm from '../../../components/ApprovalForm.jsx';
import ColumnHeader from '../../../components/common/ColumnHeader.jsx';
import ReadableDocumentNumber from '../../../components/common/ReadableDocumentNumber.jsx';
import TaskForm from '../../../components/TaskForm.jsx';
import DocumentContext from '../../../contexts/DocumentContext.jsx';
import PageContext from '../../../contexts/PageContext.jsx';

const sortOptions = {
  defaultSort: {
    field: 'order',
    reverse: false,
  },
};

function TaskTable({
  // Props
  name,
  tasks,
  plcId,
  setTableReload,
}) {
  const { documents } = React.useContext(DocumentContext);

  const { editMode } = React.useContext(PageContext);
  const canEdit = React.useMemo(() => editMode, [editMode]);

  const theme = useTheme();

  const [sortedTasks, handleSortSelection, sort] = useSort(tasks || [], sortOptions);

  return (
    <>
      <Grid container spacing={1}>
        <Grid textAlign="center" size={12}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4">{name}</Typography>
            {canEdit && <TaskForm plcId={plcId} setTableReload={setTableReload} />}
          </Stack>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={1}>
          <ColumnHeader
            id="order"
            display="Default"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 4,
            md: 2,
          }}
        >
          <ColumnHeader
            id="status"
            display="Approval"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', sm: 'block' } }}
          size={{
            sm: 4,
            md: 1.5,
          }}
        >
          <ColumnHeader
            id="section"
            display="Department"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 6,
            sm: 'grow',
          }}
        >
          <ColumnHeader
            id="name"
            display="Task"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="grow">
          <ColumnHeader
            id="documentNumber"
            display="Document"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        color={theme.palette.primary.dark}
        sx={{ borderWidth: '1px', mx: -1 }}
      />
      {sortedTasks.map((task) => (
        <React.Fragment key={task.name}>
          <Grid container spacing={1} sx={{ alignItems: 'center' }}>
            <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={1}>
              <Stack direction="row" sx={{ ml: 1 }}>
                {canEdit ? (
                  <TaskForm plcId={plcId} task={task} setTableReload={setTableReload} />
                ) : (
                  <AssignmentIcon sx={{ m: 1 }} />
                )}
              </Stack>
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
                md: 2,
              }}
            >
              <ApprovalForm task={task} plcId={plcId} setTableReload={setTableReload} />
            </Grid>
            <Grid
              sx={{ display: { xs: 'none', sm: 'block' } }}
              size={{
                sm: 4,
                md: 1.5,
              }}
            >
              {task.section}
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 'grow',
              }}
            >
              {task.name}
            </Grid>
            <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="grow">
              {!task.trackedDocument && '-'}
              {task.trackedDocument && task.documentNumber && (
                <ReadableDocumentNumber
                  documentNumber={task.documentNumber}
                  documents={documents}
                  link
                />
              )}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </>
  );
}

export default TaskTable;
