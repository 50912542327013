const taskList = [
  {
    name: 'Product IFU(s) complete and approved',
    trackedDocument: true,
    section: 'Engineering',
    order: 10,
  },
  {
    name: 'DMR complete and approved',
    trackedDocument: true,
    section: 'Engineering',
    order: 20,
  },
  {
    name: 'Design Release Milestone or ECO Completed',
    trackedDocument: true,
    section: 'Engineering',
    order: 30,
  },
  {
    name: 'Notify Bobby to complete Document 17132 for the product',
    trackedDocument: false,
    section: 'Engineering',
    order: 40,
  },
  {
    name: 'Product literature complete and approved',
    trackedDocument: true,
    section: 'Marketing',
    order: 50,
  },
  {
    name: 'Pricing added to master pricing sheet',
    trackedDocument: false,
    section: 'Marketing',
    order: 70,
  },
  {
    name: 'Marketing launch plan created',
    trackedDocument: false,
    section: 'Marketing',
    order: 80,
  },
  {
    name: 'Part numbers added to SAGE and commission spreadsheet',
    trackedDocument: false,
    section: 'Operations',
    order: 90,
  },
  {
    name: 'Part numbers added to Avalara',
    trackedDocument: false,
    section: 'Admin',
    order: 100,
  },
  {
    name: 'Part numbers added to SugarCRM',
    trackedDocument: false,
    section: 'Sales',
    order: 100,
  },
  {
    name: 'Warehouse training complete',
    trackedDocument: false,
    section: 'Operations',
    order: 120,
  },
  {
    name: 'Minimum quantity set and on the shelf',
    trackedDocument: false,
    section: 'Operations',
    order: 120,
  },
  {
    name: 'Sales training plan created',
    trackedDocument: false,
    section: 'Sales',
    order: 140,
  },
];

export const taskNames = taskList.map((el) => el.name);

export default taskList;
