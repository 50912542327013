import React from 'react';

import OpenInNew from '@mui/icons-material/OpenInNew';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const KT_URL = 'https://kt.tzmedical.com/knowledgetree/view.php?fDocumentId=';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  trailingZeroDisplay: 'stripIfInteger',
});

function ReadableCurrency({
  // Props
  value,
  documentNumber,
}) {
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  if (!documentNumber) {
    return <Typography>{formatter.format(value)}</Typography>;
  }

  return (
    <Link
      href={`${KT_URL}${documentNumber}`}
      target="_blank"
      rel="noreferrer"
      underline="always"
      onClick={disablePropagation}
    >
      <Stack direction="row" spacing={0.25} sx={{ alignItems: 'center' }}>
        <Typography>{formatter.format(value)}</Typography>
        <OpenInNew fontSize="small" />
      </Stack>
    </Link>
  );
}

export default React.memo(ReadableCurrency);
