import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { LoadingButton } from '@mui/lab';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';

import { useCheckExists } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormStringInput from './common/FormStringInput.jsx';

const statusOptions = [
  { id: 'To Do', name: 'To Do' },
  { id: 'In Progress', name: 'In Progress' },
  { id: 'Under Review', name: 'Under Review' },
  { id: 'Not Applicable', name: 'Not Applicable' },
  { id: 'Completed', name: 'Completed' },
];

function ApprovalForm({
  // Props
  task,
  plcId,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const { user } = useAuth0();

  const formTitle = React.useMemo(
    () => (task?.name ? 'Update PLC Task' : 'Add PLC Task'),
    [task?.name]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Document ID Input field
  //---------------------------------------------------------------------------
  const { documents, documentOptions } = React.useContext(DocumentContext);
  const checkDocumentExists = useCheckExists(
    documents,
    'id',
    null,
    'Please provide a valid document ID.'
  );

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      name: task?.name || '',
      documentNumber: documentOptions?.find((option) => option.id === task?.documentNumber) || '',
      status: statusOptions?.find((option) => option.id === task?.status) || '',
    }),
    [documentOptions, task?.documentNumber, task?.name, task?.status]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: task.trackedDocument,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      /* eslint-disable no-param-reassign */
      if (data.documentNumber) {
        data.documentNumber = data.documentNumber?.id;
      } else {
        data.documentNumber = undefined;
      }
      if (data.status) {
        data.status = data.status?.id;
      }
      data.plcId = plcId;
      data.name = task.name;
      data.trackedDocument = task.trackedDocument;
      data.section = task.section;
      data.order = task.order;
      if (!task.trackedDocument) {
        data.userName = user.name;
        data.userPicture = user.picture;
      }
      /* eslint-enable no-param-reassign */

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (task?.id) {
          const taskUrlComponent = encodeURIComponent(task.id);

          await axios({
            method: 'PATCH',
            url: `/api/plcTasks/${taskUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/plcTasks`,
            data,
          });
        }

        // Reset and close the modal
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [
      plcId,
      task.name,
      task.trackedDocument,
      task.section,
      task.order,
      task.id,
      user.name,
      user.picture,
      setTableReload,
      setSuccess,
    ]
  );

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      reset();
      setOpen(true);
    },
    [reset]
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const tooltip = React.useMemo(() => {
    if (task.trackedDocument) {
      if (!task.documentNumber) {
        return 'Click to enter a document number for this tracked task.';
      }
      const document = documents?.find((doc) => doc.id === task.documentNumber);
      if (document?.workflow_state.includes('Approved')) {
        return 'This document has been approved on KT.';
      }
      return 'This document needs review and approval on KT.';
    }
    if (task.userName) {
      return `${task.userName} updated this task.`;
    }
    return 'Click to update the status of this task.';
  }, [documents, task.documentNumber, task.trackedDocument, task.userName]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Tooltip arrow title={tooltip}>
        <Chip
          avatar={
            (task.userName || task.documentNumber) && (
              <Avatar alt={task.userName} src={task.userPicture} />
            )
          }
          onClick={handleOpen}
          label={task.status}
          color={task.statusColor || 'primary'}
          size="small"
          disabled={!documents.length}
          sx={{ my: 0.5 }}
        />
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <TaskAltIcon color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid size={12}>
              <FormStringInput
                control={control}
                label="Task"
                name="name"
                disabled
                required
                rules={{
                  required: 'Please enter a name for the task',
                  maxLength: {
                    value: 128,
                    message: 'Part number should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            {task.trackedDocument ? (
              <Grid size={12}>
                <FormAutocompleteInput
                  control={control}
                  label="Document Number"
                  name="documentNumber"
                  autoFocus
                  options={documentOptions}
                  rules={{
                    validate: (value) => checkDocumentExists(Number(value)),
                  }}
                />
              </Grid>
            ) : (
              <Grid size={12}>
                <FormAutocompleteInput
                  control={control}
                  label="Status"
                  name="status"
                  options={statusOptions}
                  autoFocus
                  required
                  rules={{
                    required: 'Please a status for this task',
                    maxLength: {
                      value: 128,
                      message: 'Status should be less than 128 characters',
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              Update
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.memo(ApprovalForm);
