import React from 'react';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import taskList from '../../../../shared/taskList.js';
import DocumentContext from '../../../contexts/DocumentContext.jsx';
import PartsTable from './PartsTable.jsx';
import TaskTable from './TaskTable.jsx';

function PLCData({
  // Props
  plc,
  setTableReload,
}) {
  const { documents } = React.useContext(DocumentContext);

  const tasks = React.useMemo(() => {
    const mergedTaskList = plc.plcTasks.concat(
      taskList.filter((task) => !plc.plcTasks.some((defaultTask) => defaultTask.name === task.name))
    );

    return mergedTaskList.map((task) => {
      if (task.documentNumber) {
        const document = documents?.find((doc) => doc.id === task.documentNumber);
        const statusColor = document?.workflow_state.includes('Approved') ? 'success' : 'warning';
        return {
          ...task,
          status: document?.workflow_state,
          statusColor,
        };
      }
      let statusColor = 'warning';
      if (task.status?.includes('Completed')) {
        statusColor = 'success';
      } else if (task.status?.includes('Not Applicable')) {
        statusColor = 'disabled';
      }
      return {
        status: 'To Do',
        statusColor,
        ...(task.trackedDocument && {
          status: 'Missing Document',
          statusColor: 'error',
        }),
        ...task,
      };
    });
  }, [documents, plc.plcTasks]);

  return (
    <Box sx={{ m: { xs: 0, sm: 3 } }}>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <PartsTable plcId={plc?.id} parts={plc?.parts} setTableReload={setTableReload} />
      </Container>
      <Container maxWidth="lg" sx={{ mt: 5 }}>
        <TaskTable name="Tasks" plcId={plc?.id} tasks={tasks} setTableReload={setTableReload} />
      </Container>
      <Box sx={{ m: 4, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          disabled
          startIcon={<VerifiedUserIcon />}
          data-cy="complete-plc"
        >
          Complete PLC
        </Button>
      </Box>
    </Box>
  );
}

export default PLCData;
