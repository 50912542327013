import React from 'react';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useInterval } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import { readableDocumentNumber } from '../components/common/ReadableDocumentNumber.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';

const DATA_REFRESH_INTERVAL_MS = 15 * 60 * 1000;
const DocumentContext = React.createContext();

export function DocumentProvider({ children }) {
  const [error, setError] = useAlertSnackbar('error', 0);
  const [documents, setDocuments] = React.useState([]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getDocuments = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/kt',
        });
        setDocuments(data);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error, setError, setDocuments]);
  useInterval(getDocuments, DATA_REFRESH_INTERVAL_MS, !error);

  // Memoize the value to reduce rerenders
  const value = React.useMemo(
    () => ({
      documents,
      documentOptions:
        documents?.map((document) => ({
          id: document.id,
          key: document.id,
          name: readableDocumentNumber(document.id, documents),
        })) || [],
    }),
    [documents]
  );

  return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
}

export default DocumentContext;
