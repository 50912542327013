import React from 'react';

import CategoryIcon from '@mui/icons-material/Category';

import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../../components/common/ColumnHeader.jsx';
import PartForm from '../../../components/PartForm.jsx';
import PageContext from '../../../contexts/PageContext.jsx';
import ReadableCurrency from '../ReadableCurrency.jsx';

const sortOptions = {
  defaultSort: {
    field: 'PartNumber',
    reverse: false,
  },
};

function PartsTable({
  // Props
  plcId,
  parts,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);
  const canEdit = React.useMemo(() => editMode, [editMode]);

  const theme = useTheme();

  const [sortedParts, handleSortSelection, sort] = useSort(parts || [], sortOptions);

  return (
    <>
      <Grid container spacing={1}>
        <Grid textAlign="center" size={12}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4">Product Parts</Typography>
            {canEdit && <PartForm plcId={plcId} setTableReload={setTableReload} />}
          </Stack>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="auto">
          <Icon sx={{ m: 1 }} />
        </Grid>
        <Grid
          size={{
            xs: 'grow',
            sm: 2.5,
            md: 2,
          }}
        >
          <ColumnHeader
            id="partNumber"
            display="Part Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="grow">
          <ColumnHeader
            id="description"
            display="Description"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 3.5,
            sm: 2,
          }}
        >
          <ColumnHeader
            id="basePrice"
            display="Base $"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 3.5,
            sm: 2,
          }}
        >
          <ColumnHeader
            id="listPrice"
            display="List $"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          sx={{ display: { xs: 'none', sm: 'block' } }}
          size={{
            sm: 2,
            md: 1,
          }}
        >
          <ColumnHeader
            id="unitOfMeasure"
            display="UoM"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        color={theme.palette.primary.dark}
        sx={{ borderWidth: '1px', mx: -1 }}
      />
      {sortedParts.map((part) => (
        <React.Fragment key={part.id}>
          <Grid container spacing={1} sx={{ alignItems: 'center' }}>
            <Grid sx={{ display: { xs: 'none', md: 'block' } }} size="auto">
              {canEdit ? (
                <PartForm part={part} setTableReload={setTableReload} />
              ) : (
                <CategoryIcon sx={{ mx: 1, mt: 1.5 }} />
              )}
            </Grid>
            <Grid
              size={{
                xs: 'grow',
                sm: 2.5,
                md: 2,
              }}
            >
              {part.number}
            </Grid>
            <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="grow">
              {part.description}
            </Grid>
            <Grid
              size={{
                xs: 3.5,
                sm: 2,
              }}
            >
              <ReadableCurrency value={part.basePrice} />
            </Grid>
            <Grid
              size={{
                xs: 3.5,
                sm: 2,
              }}
            >
              <ReadableCurrency value={part.listPrice} />
            </Grid>
            <Grid
              sx={{ display: { xs: 'none', sm: 'block' } }}
              size={{
                sm: 2,
                md: 1,
              }}
            >
              {part.unitOfMeasure}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </>
  );
}

export default PartsTable;
