import React from 'react';
import PropTypes from 'prop-types';

import Menu from '@mui/icons-material/Menu';

import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import ProfileDropdown from './ProfileDropdown.jsx';
import SearchBar from './SearchBar.jsx';

function TopBar({
  // Props
  onClick,
  onClose,
}) {
  const ultraNarrow = useMediaQuery('(max-width:399px)');

  return (
    <AppBar
      position="fixed"
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
      })}
    >
      <Toolbar>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
        >
          <Grid size="auto">
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={onClick}
            >
              <Menu />
            </IconButton>
          </Grid>
          <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="auto">
            <Stack direction="row" sx={{ alignItems: 'flex-start' }}>
              <img src="/favicon.ico" alt="TZ" height="32px" />
              <Typography variant="h6" noWrap component="div" sx={{ ml: '10px' }}>
                Scorecard
              </Typography>
            </Stack>
          </Grid>
          <Grid onClick={onClose} size="grow">
            <SearchBar />
          </Grid>
          {!ultraNarrow && (
            <Grid size="auto">
              <ProfileDropdown />
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;

TopBar.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func,
};
