import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Edit from '@mui/icons-material/Edit';
import HomeRepairService from '@mui/icons-material/HomeRepairService';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import axios from '../axiosClient.js';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDelete from './ConfirmDelete.jsx';

const goalTypeOptions = [
  { name: '>', id: 'greaterThan' },
  { name: '<', id: 'lessThan' },
];

const axisIdOptions = [
  { name: 'Left', id: 'leftAxisId' },
  { name: 'Right', id: 'rightAxisId' },
];

const companyOptions = [
  { name: 'TZ Medical', id: 'tz-medical' },
  { name: 'TZMMS', id: 'tzmms' },
];

const dataTypeOptions = [
  { name: 'Manual Entry', id: 'manual' },
  { name: 'Calculated Value', id: 'calculated' },
];

function MetricForm({
  // Props
  metric,
  metricsList,
  company,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (metric?.id ? 'Update Metric' : 'Add Metric'),
    [metric?.id]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      user: metric?.user || '',
      name: metric?.name || '',
      group: metric?.group || '',
      groupOrder: metric?.groupOrder || '',
      goal: metric?.goal ?? '',
      goalType: metric?.goalType || '',
      goalUnits: metric?.goalUnits || '',
      fixedDigits: metric?.fixedDigits ?? '',
      axisId: metric?.axisId || 'leftAxisId',
      company: metric?.company || company || 'tz-medical',
      dataType: metric?.dataType || 'manual',
      calculationNumerator: metric?.calculationNumerator || '',
      calculationDenominator: metric?.calculationDenominator || '',
      calculationScalar: metric?.calculationScalar || '',
    }),
    [
      company,
      metric?.axisId,
      metric?.calculationDenominator,
      metric?.calculationNumerator,
      metric?.calculationScalar,
      metric?.company,
      metric?.dataType,
      metric?.fixedDigits,
      metric?.goal,
      metric?.goalType,
      metric?.goalUnits,
      metric?.group,
      metric?.groupOrder,
      metric?.name,
      metric?.user,
    ]
  );

  const metricOptions = React.useMemo(() => {
    const list = metricsList
      ?.map(({ name, id }) => ({ name, id }))
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    list.push({ name: '-None-', id: 'none' });
    return list;
  }, [metricsList]);

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      ['goal', 'calculationNumerator', 'calculationDenominator', 'calculationScalar'].forEach(
        (el) => {
          if (data[el] === '' || data[el] === 'none') {
            // eslint-disable-next-line no-param-reassign
            data[el] = undefined;
          }
        }
      );
      if (data.calculationScalar) {
        // eslint-disable-next-line no-param-reassign
        data.calculationScalar = Number(data.calculationScalar);
      }
      if (data.groupOrder) {
        // eslint-disable-next-line no-param-reassign
        data.groupOrder = Number(data.groupOrder);
      }

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (metric) {
          const metricUrlComponent = encodeURIComponent(metric.id);

          await axios({
            method: 'PATCH',
            url: `/api/metrics/${metricUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/metrics`,
            data,
          });
        }

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [metric, reset, defaultValues, setTableReload, setSuccess]
  );

  //---------------------------------------------------------------------------
  // Disable Confirmation Form
  //---------------------------------------------------------------------------
  const handleDeleteConfirmation = React.useCallback(async () => {
    if (metric) {
      const metricUrlComponent = encodeURIComponent(metric.id);
      await axios({
        method: 'DELETE',
        url: `/api/metrics/${metricUrlComponent}`,
      });

      // Reset and close the modal
      reset(defaultValues);
      setOpen(false);

      // Update the reload state to trigger a data re-fetch
      if (setTableReload) {
        setTableReload(true);
      }
    }
  }, [defaultValues, metric, reset, setTableReload]);

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset(defaultValues);
    setOpen(false);
  }, [reset, defaultValues]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {metric?.id ? (
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{ display: defaultValues.disabled ? 'none' : 'block' }}
          data-cy={`edit-metric-row-${metric?.id}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<HomeRepairService />}
          data-cy="add-metric"
        >
          Add Metric
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        id="MetricForm"
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <HomeRepairService color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid
              size={{
                xs: 12,
                sm: 2,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Person"
                name="user"
                required
                rules={{
                  required: "Please provide a peron's name for the metric",
                  maxLength: {
                    value: 255,
                    message: "Person's name should be less than 256 characters",
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Group"
                name="group"
                required
                rules={{
                  required: 'Please provide a group name for the metric',
                  maxLength: {
                    value: 255,
                    message: 'Group name should be less than 256 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 2,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Group Order"
                name="groupOrder"
                required
                type="number"
                rules={{
                  required: 'Please provide an ordering index for the metric',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Name"
                name="name"
                required
                rules={{
                  required: 'Please provide a name for this metric',
                  maxLength: {
                    value: 255,
                    message: 'Metric name should be less than 256 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Goal Type"
                name="goalType"
                options={goalTypeOptions}
                rules={{
                  maxLength: {
                    value: 30,
                    message: 'Goal Type should be less than 31 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormStringInput
                control={control}
                label="Goal Value"
                name="goal"
                type="number"
                rules={{
                  min: 0,
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormStringInput
                disabled={defaultValues.disabled}
                control={control}
                label="Goal Units"
                name="goalUnits"
                rules={{
                  maxLength: {
                    value: 30,
                    message: 'Goal Units should be less than 31 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 3,
              }}
            >
              <FormStringInput
                control={control}
                label="Digits after Decimal"
                name="fixedDigits"
                rules={{
                  min: 0,
                  max: 5,
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Chart Y-Axis"
                name="axisId"
                options={axisIdOptions}
                rules={{
                  maxLength: {
                    value: 30,
                    message: 'Chart Axis should be less than 31 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Company"
                name="company"
                options={companyOptions}
                rules={{
                  maxLength: {
                    value: 30,
                    message: 'Company should be less than 31 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Data Type"
                name="dataType"
                options={dataTypeOptions}
                rules={{
                  maxLength: {
                    value: 30,
                    message: 'Data Type should be less than 31 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Calculation Numerator"
                name="calculationNumerator"
                options={metricOptions}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Calculation Denominator"
                name="calculationDenominator"
                options={metricOptions}
              />
            </Grid>
            <Grid
              size={{
                xs: 6,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Calculation Scalar"
                name="calculationScalar"
                type="number"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, marginRight: 'auto' }}>
            {!!metric && (
              <ConfirmDelete
                handleConfirmation={handleDeleteConfirmation}
                confirmKeyword={metric.name}
                name="metric"
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {metric?.id ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default MetricForm;
