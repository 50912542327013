import React from 'react';
import { useLocation } from 'react-router-dom';

import ListItem from '@mui/material/ListItem';

import { useFilter, useInterval, useSort } from '@tzmedical/react-hooks';

import axios from '../../axiosClient.js';
import Alert from '../../components/common/Alert.jsx';
import PageLoading from '../../components/common/PageLoading.jsx';
import EditButton from '../../components/EditButton.jsx';
import PLCForm from '../../components/PLCForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import SearchContext from '../../contexts/SearchContext.jsx';
import PLCRow from './PLCRow.jsx';
import PLCsHeader from './PLCsHeader.jsx';

//-----------------------------------------------------------------------------
// Page configuration
//-----------------------------------------------------------------------------
const DATA_REFRESH_INTERVAL_MS = 15000;
const searchFields = {
  product: 'name',
  part: (object) => {
    if (object.parts?.length && Array.isArray(object.parts)) {
      return object.parts.reduce(
        (text, part) => `${text}, ${part.number} - ${part.description}`,
        ''
      );
    }
    return '';
  },
  engineering: 'engineeringRelease',
  launch: 'launchDate',
  revenue: 'thirdYearRevenue',
};
const sortOptions = {
  defaultSort: [
    {
      field: 'launchDate',
      reverse: false,
    },
  ],
};

//-----------------------------------------------------------------------------
function PLCPage() {
  const location = useLocation();

  const [PLCsList, setPLCsList] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [tableLoading, setTableLoading] = React.useState(true);

  //---------------------------------------------------------------------------
  // Navbar button
  //---------------------------------------------------------------------------
  const { setPageButtons } = React.useContext(PageContext);
  React.useEffect(() => {
    setPageButtons(
      <span>
        <ListItem>
          <PLCForm setTableReload={setTableLoading}>Add Checklist</PLCForm>
        </ListItem>
        <ListItem sx={{ mb: 1 }}>
          <EditButton>Edit Data</EditButton>
        </ListItem>
      </span>
    );
    return () => setPageButtons(null);
  }, [setPageButtons, location]);

  //---------------------------------------------------------------------------
  // Periodically refetch the data
  //---------------------------------------------------------------------------
  const getMetrics = React.useCallback(async () => {
    try {
      if (!error) {
        const { data } = await axios({
          method: 'GET',
          url: '/api/plcs',
        });

        setPLCsList(data);
        setError(null);
        setTableLoading(false);
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    }
  }, [error]);
  useInterval(getMetrics, DATA_REFRESH_INTERVAL_MS, tableLoading && !error);

  //---------------------------------------------------------------------------
  // Search
  //---------------------------------------------------------------------------
  const { search, setSearch } = React.useContext(SearchContext);
  const filteredPLCs = useFilter(PLCsList, search, searchFields);
  const [sortedPLCs, handleSortSelection, sort] = useSort(filteredPLCs, sortOptions);
  React.useEffect(() => {
    return () => {
      // Reset the search bar every time we navigate to a new page
      setSearch('');
      setPLCsList([]);
      setTableLoading(true);
      setError('');
    };
  }, [setSearch, location]);

  if (tableLoading && !PLCsList.length) {
    return (
      <>
        <Alert message={error} setMessage={setError} level="error" />
        {!error && <PageLoading />}
      </>
    );
  }

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Alert message={error} setMessage={setError} level="error" />
      <PLCsHeader sort={sort} setSort={handleSortSelection} />
      {sortedPLCs.map((plc) => (
        <PLCRow key={plc.id} plc={plc} setTableReload={setTableLoading} />
      ))}
    </>
  );
}

export default PLCPage;
