import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';

import ThemePicker from './ThemePicker.jsx';

function ProfileDropdown() {
  const { user, logout } = useAuth0();
  const [anchorElement, setAnchorElement] = React.useState(null);

  // handle opening the profile dropdown menu
  const handleProfilePictureClick = React.useCallback((event) => {
    setAnchorElement(event.currentTarget);
  }, []);

  // handle closing the profile dropdown menu
  const handleProfileDropdownClose = React.useCallback(() => setAnchorElement(null), []);

  // handles the user logging out
  const handleLogout = React.useCallback(() => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, [logout]);

  return (
    <>
      <Avatar
        alt={user?.name}
        src={user?.picture}
        onClick={handleProfilePictureClick}
        sx={{
          cursor: 'pointer',
          outline: false,
          transition: 'outline 0.05s',
          '&:hover': { outline: '2px solid white' },
        }}
        data-cy="profile-picture"
      />
      <Menu
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleProfileDropdownClose}
        slotProps={{
          paper: {
            sx: {
              mt: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              width: 375,
            },
          },
        }}
        data-cy="profile-dropdown"
      >
        <Box
          sx={{
            p: 2,
            position: 'relative',
            top: 0,
            left: 0,
          }}
        >
          <Grid
            container
            sx={{
              p: 2,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid size={5}>
              <Avatar
                alt={user?.name}
                src={user?.picture}
                sx={{ my: 2, mr: 2, ml: '30px', width: 75, height: 75 }}
              />
            </Grid>
            <Grid data-cy="user-details" size={7}>
              <Typography
                data-cy="user-name"
                sx={{
                  marginBottom: 2,
                }}
              >
                {user?.name} ({user?.nickname})
              </Typography>
              <Typography
                data-cy="user-email"
                sx={{
                  marginBottom: 2,
                }}
              >
                {user?.email}
              </Typography>
            </Grid>
          </Grid>
          <ThemePicker />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              pt: 2,
            }}
          >
            <Box
              sx={{
                p: 1,
              }}
            >
              <Button sx={{ m: 1 }} onClick={handleLogout} data-cy="sign-out-button">
                Sign Out
              </Button>
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
}

export default React.memo(ProfileDropdown);
