import React from 'react';

import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';

import PLCForm from '../../components/PLCForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import PLCData from './PLCData/index.jsx';
import ReadableCurrency from './ReadableCurrency.jsx';

function PLCRow({
  // Props
  plc,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);

  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);
  const ref = React.useRef(null);

  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);

  React.useEffect(() => {
    if (expanded) {
      setTimeout(() => {
        const y = ref.current?.getBoundingClientRect().top;
        if (y) {
          window.scrollTo({ top: y + window.scrollY - 70, behavior: 'smooth' });
        }
      }, 300);
    }
  }, [expanded]);

  return (
    <Card square ref={ref} sx={{ my: 0.5, ...(expanded && { my: 1, boxShadow: 6 }) }}>
      <Grid
        container
        spacing={1}
        onClick={handleExpandClick}
        sx={{
          alignItems: 'center',
          p: '0.5rem',
          minHeight: '3.5rem',
          ...(expanded && { fontWeight: 'bold' }),
        }}
      >
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="auto">
          {editMode ? (
            <PLCForm plc={plc} setTableReload={setTableReload} />
          ) : (
            <RocketLaunchIcon sx={{ m: 1 }} />
          )}
        </Grid>
        <Grid size="grow">{plc.name}</Grid>
        <Grid size="grow">
          {plc?.parts?.map((part) => (
            <Chip key={part.id} label={part.number} sx={{ m: 0.5 }} />
          ))}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          {plc.engineeringRelease}
        </Grid>
        <Grid
          size={{
            xs: 4,
            sm: 2,
          }}
        >
          {plc.launchDate}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ReadableCurrency value={plc.thirdYearRevenue} documentNumber={plc.icrDocumentNumber} />
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <PLCData plc={plc} setTableReload={setTableReload} />
      </Collapse>
    </Card>
  );
}

export default PLCRow;
