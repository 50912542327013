import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@mui/material/LinearProgress';

import ExternalLink from './ExternalLink.jsx';

const KT_URL = 'https://kt.tzmedical.com/knowledgetree/view.php?fDocumentId=';

export function readableDocumentNumber(
  // Props
  documentNumber,
  documents
) {
  if (!documentNumber) {
    return '-';
  }
  const document = documents?.find((doc) => doc.id === documentNumber);
  return `${documentNumber}${document?.name ? ` - ${document?.name}` : ''}`;
}

function ReadableDocumentNumber({
  // Props
  documentNumber,
  documents,
  link,
}) {
  if (!documents.length) {
    return <LinearProgress sx={{ width: '80%', mt: '1em' }} color="secondary" />;
  }
  if (link) {
    return (
      <ExternalLink
        link={`${KT_URL}${documentNumber}`}
        title={readableDocumentNumber(documentNumber, documents)}
      />
    );
  }
  return readableDocumentNumber(documentNumber, documents);
}

ReadableDocumentNumber.propTypes = {
  documentNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default React.memo(ReadableDocumentNumber);
