import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CategoryIcon from '@mui/icons-material/Category';
import Edit from '@mui/icons-material/Edit';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import axios from '../axiosClient.js';
import useAuth0Roles from '../hooks/auth0Roles.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmDelete from './ConfirmDelete.jsx';

function PartForm({
  // Props
  part,
  plcId,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (part?.id ? 'Update Product Part Number' : 'Add Product Part Number'),
    [part?.id]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      number: part?.number || '',
      description: part?.description || '',
      unitOfMeasure: part?.unitOfMeasure || '',
      basePrice: part?.basePrice || '',
      listPrice: part?.listPrice || '',
    }),
    [part?.basePrice, part?.description, part?.listPrice, part?.number, part?.unitOfMeasure]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      /* eslint-disable no-param-reassign */
      if (data.basePrice !== '') {
        data.basePrice = Number(data.basePrice);
      } else {
        data.basePrice = undefined;
      }
      if (data.listPrice !== '') {
        data.listPrice = Number(data.listPrice);
      } else {
        data.listPrice = undefined;
      }
      data.plcId = plcId;
      /* eslint-enable no-param-reassign */

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (part) {
          const partUrlComponent = encodeURIComponent(part.id);

          await axios({
            method: 'PATCH',
            url: `/api/parts/${partUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/parts`,
            data,
          });
        }

        // Reset and close the modal
        reset(defaultValues);
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [plcId, part, reset, defaultValues, setTableReload, setSuccess]
  );

  const handleOpen = React.useCallback((event) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleClose = React.useCallback(() => {
    reset();
    setOpen(false);
  }, [reset]);

  //---------------------------------------------------------------------------
  // Delete Confirmation Form
  //---------------------------------------------------------------------------
  const { roles } = useAuth0Roles();
  const canDelete = React.useMemo(() => roles.includes('Scorecard Admin'), [roles]);
  const handleDeleteConfirmation = React.useCallback(async () => {
    if (part) {
      const partUrlComponent = encodeURIComponent(part.id);
      await axios({
        method: 'DELETE',
        url: `/api/parts/${partUrlComponent}`,
      });

      // Reset and close the modal
      handleClose();

      // Update the reload state to trigger a data re-fetch
      if (setTableReload) {
        setTableReload(true);
      }
    }
  }, [handleClose, part, setTableReload]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {part?.id ? (
        <IconButton
          color="primary"
          onClick={handleOpen}
          sx={{ mt: 0.5 }}
          data-cy={`edit-plc-part-row-${part?.id}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <IconButton color="secondary" onClick={handleOpen} sx={{ mt: 0.5 }} data-cy="add-plc-part">
          <AddCircleIcon />
        </IconButton>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <CategoryIcon color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                label="Part Number"
                name="number"
                required
                rules={{
                  required: 'Please enter a part number',
                  maxLength: {
                    value: 16,
                    message: 'Part number should be less than 17 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                label="Description"
                name="description"
                required
                rules={{
                  required: 'Please enter a description for this part number',
                  maxLength: {
                    value: 127,
                    message: 'Product description should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Unit of Measure"
                name="unitOfMeasure"
                required
                rules={{
                  required: 'Please enter a unit of measure for this part number',
                  maxLength: {
                    value: 127,
                    message: 'Units of measure should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Base Price"
                name="basePrice"
                type="number"
                units="$"
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="List Price"
                name="listPrice"
                type="number"
                units="$"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, marginRight: 'auto' }}>
            {!!part && canDelete && (
              <ConfirmDelete
                handleConfirmation={handleDeleteConfirmation}
                confirmKeyword={part.number}
                name="Part"
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {part?.id ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PartForm;
