import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Icon from '@mui/material/Icon';

import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function PLCsHeader({
  // Props
  sort,
  setSort,
}) {
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid container id="headersRow" sx={{ alignItems: 'flex-end' }} spacing={1}>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size="auto">
          <Icon sx={{ m: 1 }} />
        </Grid>
        <Grid size="grow">
          <ColumnHeader
            id="name"
            display="Product"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size="grow">
          <ColumnHeader
            id="Parts"
            display="Part Numbers"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ColumnHeader
            id="engineeringReleaseDate"
            display="Engineering Release"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid
          size={{
            xs: 4,
            sm: 2,
          }}
        >
          <ColumnHeader
            id="launchDate"
            display="(Soft) Launch Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ColumnHeader
            id="thirdYearRevenue"
            display="Projected Revenue"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PLCsHeader;
